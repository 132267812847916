import * as React from 'react';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import AboutUsContent from '../components/AboutUs';
import { aboutUsTitle } from '../seoData/titles';
import { aboutUsDescription } from '../seoData/descriptions';
import { graphql } from 'gatsby';
import { HomeData } from '../components/Home/types';

export const query = graphql`
  query AboutUsPageQuery {
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
    sanityLifestyle(id: { eq: "-2ed0a586-971e-557d-baf1-beebebb5ed91" }) {
      title
      description
      images {
        asset {
          url
        }
      }
    }
  }
`;

const AboutUs = ({ data }: HomeData) => {
  const allSanityFooter = data.allSanityFooter;
  const sanityLifestyle = data.sanityLifestyle;

  return (
    <PageLayout footerData={allSanityFooter} title={aboutUsTitle} description={aboutUsDescription}>
      <AboutUsContent sanityLifestyle={sanityLifestyle} />
    </PageLayout>
  );
};

export default AboutUs;
