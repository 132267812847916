import desktopMap from './png/desktop_map.png';
import tabletMap from './png/tablet_map.png';
import mobileMap from './png/mobile_map.png';

const PNG_ICONS_ABOUT_US = {
  DESKTOP_MAP: desktopMap,
  TABLET_MAP: tabletMap,
  MOBILE_MAP: mobileMap,
};

export default PNG_ICONS_ABOUT_US;
