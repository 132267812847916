import * as React from 'react';
import SVG_ICONS_ABOUT_US from '../../../images/about_us/svg';
import { useFirebaseStorage } from '../../../hooks/useFirebaseStorage';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Join.scss';

const JoinUs: React.FC = () => {
  const { uploadFileAsync, extractFile, uploading, resume, validateFileSize, progress } = useFirebaseStorage();
  const [recaptchaRef, setRecaptchaRef] = useState<any>(null);
  const renderImage = () => {
    const cvIcon = !uploading && !resume && <img src={SVG_ICONS_ABOUT_US.RESUME} alt="resume icon" />;

    if (progress === 100) {
      return (
        <div className="join__sentBox">
          <img src={SVG_ICONS_ABOUT_US.RESUME_UPLOADED} alt="resume icon" />
          <p>Your resume was sent to our e-mail successfully!</p>
        </div>
      );
    }

    return <div className="join__sentBox">{cvIcon}</div>;
  };

  const onChangeHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const token = await recaptchaRef.executeAsync();

    try {
      if (!extractFile(e)) {
        return;
      }

      if (!validateFileSize(e, 3)) {
        new Error('The file size could not be saved because it exceeded 3MB, the maximum allowed size to upload');
      }

      if (token) {
        await uploadFileAsync(e);

        return recaptchaRef.reset();
      }

      new Error('Try once more. Are you robot))');
    } catch (error) {
      console.error(`Submit resume: ${error.message}`);
    }
  };

  return (
    <section className="join">
      <div className="join__container">
        <div className="join__text">
          <h3>Join us!</h3>
          <p>
            Gain your experience working with the driven talents and super professionals. Send your CV and our HR team
            will contact you as soon as possible.
          </p>
          <form>
            <label htmlFor="resumeIcon">Send Resume</label>
            <input
              className="inputFile"
              id="resumeIcon"
              type="file"
              accept=".png, .jpeg, .pdf"
              onChange={(e) => onChangeHandler(e)}
            />
            <ReCAPTCHA
              ref={(ref) => setRecaptchaRef(ref)}
              sitekey={process.env.RECAPTCHA_SITE_KEY || ''}
              size="invisible"
            />
          </form>
        </div>
        {renderImage()}
      </div>
    </section>
  );
};

export default JoinUs;
