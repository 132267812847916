import * as React from 'react';
import './VisitUs.scss';
import ROUTES from '../../../constants/routes';
import PNG_ICONS_ABOUT_US from '../../../images/about_us/png';

const VisitUs = () => {
  return (
    <section className="visit">
      <div className="visit__container">
        <div className="visit__route">
          <h2>Come to Visit us</h2>
          <p>You’re always welcome to visit us in our offices and meet the team personally.</p>
          <picture className="map">
            <source srcSet={PNG_ICONS_ABOUT_US.DESKTOP_MAP} media="(min-width: 1200px)" />
            <source srcSet={PNG_ICONS_ABOUT_US.TABLET_MAP} media="(min-width: 471px)" />
            <img loading="lazy" src={PNG_ICONS_ABOUT_US.MOBILE_MAP} alt="map" />
          </picture>
          <a target="_blank" href={ROUTES.MAP}>
            Show on map
          </a>
        </div>
      </div>
    </section>
  );
};

export default VisitUs;
