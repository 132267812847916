import * as React from 'react';
import Main from '../_Shared/Main/Main';
import OurValues from '../_Shared/OurValues/OurValues';
import Team from './Team/Team';
import Testimonials from '../_Shared/Testimonials/Testimonials';
import Lifestyle from './Lifestyle/Lifestyle';
import VisitUs from './VisitUs/VisitUs';
import Lines from '../_Shared/Lines/Lines';
import JoinUs from './JoinUs/Join';
import { LifeStyleData } from '../Home/types';

export interface AboutUsContentProps {
  sanityLifestyle: LifeStyleData;
}

const AboutUsContent = ({ sanityLifestyle }: AboutUsContentProps) => {
  return (
    <>
      <Main
        className="aboutUs"
        title="We help businesses implement bold business ideas with the help of innovative technology"
      />
      <OurValues />
      <Team />
      <Testimonials />
      <Lifestyle lifestyle={sanityLifestyle} />
      <VisitUs />
      <Lines />
      <JoinUs />
    </>
  );
};

export default AboutUsContent;
