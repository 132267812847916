import React from 'react';
import { QueryResponseMemberData } from '../Team/types';
import './TeammateCard.scss';
import SVG_ICONS_SHARED from '../../../images/_shared/svg';

const TeammateCard = ({ photo, position, mail, linkedin, name }: QueryResponseMemberData, index: number) => {
  return (
    <div key={index} className="team__container__boxes">
      <div className="box_content">
        <img loading="lazy" src={photo.asset.url} width="100%" height="460px" alt="teammate photo" />
        <div className="content_text">
          <span>{name}</span>
          <p>{position}</p>
        </div>
      </div>
    </div>
  );
};

export default TeammateCard;
