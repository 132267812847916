import * as React from 'react';
import './Lifestyle.scss';
import { LifeStyleProps } from '../../Home/types';
import { Autoplay, Keyboard, Navigation, Pagination, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Lifestyle = ({ lifestyle, imageHeight = 'auto' }: LifeStyleProps) => {
  const { images, title, description } = lifestyle;

  return (
    <section className="lifestyle">
      <div className="lifestyle__container">
        <h2>{title}</h2>
        {description && <p>{description}</p>}
        <Swiper
          mousewheel={{ releaseOnEdges: true }}
          autoplay={{ delay: 3000 }}
          slidesPerView={4}
          spaceBetween={6}
          keyboard={{ enabled: true }}
          breakpoints={{
            3500: {
              slidesPerView: 9,
            },
            3100: {
              slidesPerView: 8,
            },
            2700: {
              slidesPerView: 7,
            },
            2300: {
              slidesPerView: 6,
            },
            1920: {
              slidesPerView: 5,
            },
            1520: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 3,
            },
            960: {
              slidesPerView: 4,
            },
            768: {
              slidesPerView: 3,
            },
            670: {
              slidesPerView: 4,
            },
            512: {
              slidesPerView: 3,
            },
            320: {
              slidesPerView: 2,
            },
          }}
          modules={[Keyboard, Pagination, Navigation, Autoplay, Mousewheel]}
        >
          {images.map(({ asset: { url } }, index: number) => {
            return (
              <SwiperSlide key={index}>
                <img loading="lazy" key={index} src={url} width="100%" height={imageHeight} alt="process" />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};

export default Lifestyle;
