import { graphql, useStaticQuery } from 'gatsby';

export const useTeam = () => {
  const teamQueryData = useStaticQuery(graphql`
    query TeamQuery {
      allSanityTeamMember(sort: { order: ASC, fields: _createdAt }) {
        nodes {
          linkedin
          photo {
            asset {
              url
            }
          }
          mail
          position
          name
        }
      }
    }
  `);
  const allMembers = teamQueryData.allSanityTeamMember.nodes;

  return { allMembers };
};
