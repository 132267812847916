import * as React from 'react';
import './Team.scss';
import TeammateCard from '../TeammateCard/TeammateCard';
import { QueryResponseMemberData } from './types';
import { useTeam } from './useTeam';
import { Keyboard, Navigation, Pagination, Mousewheel } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Team = () => {
  const { allMembers } = useTeam();
  const title = 'Our Team';
  const renderTeam = () => {
    return (
      <Swiper
        slidesPerView={5}
        spaceBetween={6}
        mousewheel={{ releaseOnEdges: true }}
        breakpoints={{
          3500: {
            slidesPerView: 9,
          },
          3100: {
            slidesPerView: 8,
          },
          2700: {
            slidesPerView: 7,
          },
          2300: {
            slidesPerView: 6,
          },
          1920: {
            slidesPerView: 5,
          },
          1600: {
            slidesPerView: 4,
          },
          768: {
            slidesPerView: 3,
          },
          500: {
            slidesPerView: 3,
          },
          320: {
            slidesPerView: 2,
          },
        }}
        keyboard={{ enabled: true }}
        modules={[Keyboard, Pagination, Navigation, Mousewheel]}
      >
        {allMembers.map(({ photo, name, position, linkedin, mail }: QueryResponseMemberData, index: number) => {
          return (
            <SwiperSlide key={index}>
              <TeammateCard key={index} photo={photo} name={name} position={position} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  };

  return (
    <section className="team">
      <div className="team__container">
        <h2>{title}</h2>
        <p>
          Our team is always ready to easily clarify your business issues by deep diving into project and providing
          complex development solutions that meet your business needs.
        </p>
        <div className="team__wrapper">{renderTeam()}</div>
      </div>
    </section>
  );
};

export default Team;
